
import Vue from 'vue';
import HelpCard from '@/components/shared/HelpCard.vue';

export default Vue.extend({
  components: { HelpCard },
  data() {
    return { focusFor: '' };
  },
});
