










import Vue from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    highlightName: {
      type: String,
      required: false,
    },
    focusFor: {
      type: String,
      required: false,
    },
    textVariables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    isHighlight(): boolean {
      return this.name == this.focusFor || this.highlightName == this.focusFor;
    },
    isLowlight(): boolean {
      return this.name != this.focusFor && this.focusFor != '' && this.highlightName != this.focusFor;
    },
  },
});
