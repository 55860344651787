




import Vue from 'vue';
export default Vue.extend({
  methods: {
    discardChanges() {
      this.$emit('discardChanges');
    },
  },
});
