































import Vue from 'vue';
import LoadingCardBody from './LoadingCardBody.vue';
import SaveButton from '@/components/forms/SaveButton.vue';
import ErrorMessage from '@/components/shared/ErrorMessage.vue';
import PartSaveStatus from '@/components/shared/PartSaveStatus.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import DiscardChangesButton from '@/components/forms/DiscardChangesButton.vue';

export default Vue.extend({
  props: {
    ready: {
      type: Boolean,
      required: true,
    },
    titleTranslationKey: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: false,
    },
    onSave: {
      type: Function,
      required: false,
    },
    titleTooltipTranslationKey: {
      type: String,
      required: false,
    },
    additionalErrorMessage: {
      type: String,
      required: false,
      default: '',
    },
    showDiscardButton: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: { LoadingCardBody, SaveButton, ErrorMessage, PartSaveStatus, DiscardChangesButton },
  data() {
    return {
      errorMessage: null as string | null,
      partSaveStatusType: PartSaveStatusType.Unknown,
      saving: false,
    };
  },
  watch: {
    internalModel: {
      deep: true,
      handler() {
        if (!this.model.$anyDirty) {
          this.partSaveStatusType = PartSaveStatusType.Unchanged;
        } else {
          if (this.model.$invalid) {
            this.partSaveStatusType = PartSaveStatusType.Invalid;
          } else {
            this.partSaveStatusType = PartSaveStatusType.Unsaved;
          }
        }
      },
    },
    partSaveStatusType: {
      handler() {
        this.$emit('part-save-status-type-change', this.partSaveStatusType);
      },
    },
  },
  methods: {
    setErrorMessage(data: String | { message: string }) {
      if (typeof data === 'string' || data instanceof String) {
        this.errorMessage = data as string;
      } else {
        this.errorMessage = data.message;
      }
    },

    externalSave(): Promise<boolean> {
      return this.internalOnSave();
    },

    async internalOnSave(): Promise<boolean> {
      this.saving = true;
      return this.onSave().then(
        (success: boolean) => {
          if (success) {
            this.partSaveStatusType = PartSaveStatusType.Saved;
          } else {
            this.partSaveStatusType = PartSaveStatusType.Invalid;
          }
          this.saving = false;
          this.model.$reset();
          return Promise.resolve(success);
        },
        (error: string) => {
          this.partSaveStatusType = PartSaveStatusType.Invalid;
          this.saving = false;
          return Promise.reject(error);
        }
      );
    },
  },
});
