











import Vue from 'vue';
export default Vue.extend({
  props: {
    ready: {
      type: Boolean,
      required: true,
    },
  },
});
