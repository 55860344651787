






import Vue from 'vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';

export default Vue.extend({
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    show(): boolean {
      return (
        this.status != PartSaveStatusType.Unknown &&
        this.status != PartSaveStatusType.Unchanged &&
        this.status != PartSaveStatusType.Saved
      );
    },
    text(): string {
      return this.$t('enums.PartSaveStatusType.' + this.status).toString();
    },
  },
});
